.button{
    position: relative;
    display: block;
    background: $primary;

    border: none;
    border-radius: 3px;
    padding: 10px 35px;

    color: $light;
    font-family: "Poppins";
    font-weight: 500;
    text-transform: capitalize;

    cursor: pointer;
}

.font-btn{
    font-family: "poppins", sans-serif;
}

.infos-link{
    background: none !important;
    border: 1px solid rgba($color: $light, $alpha: .3) !important;
    
}

.btn{
    display: block;
    cursor: pointer;
    
    border: none;
    height: 45px;
    padding: 0 25px;
    font-family: Poppins;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &>span{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.btn-devis{
    border-radius: 2px;
    padding: 10px 15px;
}
.btnclass{
    display: block;
    border-radius: 25px;
    border:2px solid $primary !important;
}
.btn-commande{
    border-radius: 2px;
    padding: 10px 15px;
    width: 100%;
    background: $dark;
    color: $light;
    
}

.btn-see_article{
    display: flex;
    
    align-items: center;
    gap: 3px;
    cursor: pointer;

    border: none;
    background: transparent;
    span{
        font-size: 1.2rem;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;

        border-radius: 50%;
        padding: 5px;
        transition: all 250ms ease-in;
    }
    &:hover{
        span{
            background: rgba($color: $primary, $alpha: .3);
            color: $light;
        }
    }
}

.btn-admin_button{
    background: #4B39E3;
    color: $light;
    font-family: "Poppins", sans-serif;

    border-radius: 3px;
    padding: 10px 25px;
    border: none;
    cursor: pointer;
}

.logout{
    color: rgba($color: $dark, $alpha: .5);
    
    border: none;
    cursor: pointer;
    
    background: none;
    span{
        font-size: 3.5rem;
    }
}
.backbutton{
    position: fixed;
    top: 15px;
    right: 55px;
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: none;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;

    .back_{
        font-size: 1.4rem;
        transition: all 250ms linear;
        display: flex;
        align-items: center;
        justify-content: center;    
    }
    
}
.border-radius{
    border-radius: 25px;
}
@media screen and (max-width: 1024px) {
    .backbutton{
        top: 25px;
        right: 25px;

        height: 30px;
        width: 30px;
        position: absolute;
    }
}
@media screen and (max-width: 768px) {
    .btnclass{
        display: none;
    }
}