#login{
    background: $dark;
    min-height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        
        color: $light;
        form{
            padding: 25px;
            border-radius: 25px;
            background: transparent;
            backdrop-filter: blur(5px);
            border: 1px solid rgba($color: $light, $alpha: .2);
            max-width: 350px;
            box-shadow: 1px 1px 2px 2px rgba($color: $dark, $alpha: .08);
            
            display: flex;
            flex-direction: column;
            gap: 25px;
            .form-group{
                position: relative;
                &::before{
                    background: rgba($color: $light, $alpha: .5);
                }
                input{
                    padding: 0 15px;
                    background: none;
                    height: 100%;
                    color: $light;
                    font-family: Poppins;
                    width: 100%;  
                }
                
                ::placeholder{
                    color: rgba($color: $light, $alpha: .6);
                }
                .showpassword{
                    position: absolute;
                    right: 15px;
                    top: 20px;
                    color: $primary;
                    
                    cursor: pointer;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background: rgba($color: $primary, $alpha: .2);
                    border-radius: 50%;
                    
                    height: 25px;
                    width: 25px;
                    font-size: .9rem;
                    border: none;
                    z-index: 2;
                }
            }
        }
    }
}
