/*
* Google fonts
**/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
/*
* Les coleurs de bases
**/
$primary: #fb923c;
$secondary: #0E223B;
$dark: #030202;
$light: #F3F3F3;

$info: #cae7f7;
$success: #caf7ca;
$warning: #fce3baed;
$danger: #f8ada0ed

