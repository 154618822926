#header{
    background: transparent;
    transition: background 400ms ease-in-out;
    .dash{
        background: $primary;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            color: $light;
        }
    }
    &>div{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navbar{
            .list_nav{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 45px;
                .nav_item{
                    position: relative;
                    font-weight: 500;
                    text-transform: capitalize;
                    color: $light;
                
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &>.active:before{
                        content: "";
                        height: 2px;
                        width: 100%;

                        position: absolute;
                        bottom: 0;
                        left: 0;

                        background: $primary;
                        opacity: 1;
                    }
                }
            }
        }
        .burger-menu{
            z-index: 10;
            display: none;

            visibility: hidden;
            width: 50px;
            height: 50px;
            position: relative;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 6px;
            cursor: pointer;    
        }
        .header_contact{
            position: absolute;
            right: 0;
        }
    }
}
.brand{
    margin: 0 auto;
    figure{
        img{
            height: 100%;
            width: 100%;

            object-fit: contain;
        }
    }
}

#header.scrolling{
    background: $dark;
}

.btncontact{
    display: block;
}
@media screen and (max-width: 1024px) {
    #header{
        max-height: 70px;
        &>div{
            justify-content: space-between;
            width: 100%;
            height: 70px;
            .navbar{
                visibility: hidden;
                opacity: 0;
                width: 100%;
                height: 100vh;

                position: absolute;
                top: 0;
                right: -100%;
                margin-left: 0;
                transition: all 250ms ease-in-out;
                .list_nav{
                    flex-direction: column;
                    height: 100%;
                    .nav_item{
                        &:last-child{
                            display: block;    
                        }
                    }
                }
            }
            .navbar.show{
                background: rgba($color: $dark, $alpha: .90);
                opacity: 1;
                visibility: visible;
                right: 0;
            }
            .brand{
                visibility: visible;
                display: block;
                margin-left: 25px;
                height: 30px;
                width: 30px;
                figure{
                    height: 30px;
                    width: 30px;
                }
            }
            .burger-menu{
                visibility: visible;
                display: flex;
                margin-right: 25px;
            }
            .header_contact{
                visibility: hidden;
                display: none;
            }
            .nav_contact_button{
               visibility: visible;
               opacity: 1;
            }
        }
    }
    .btncontact{
        display: none !important;
    }
}

@media screen and (max-width: 425px) {
    
}