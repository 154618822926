#orderCustom{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    padding: 70px 0;
    &>div{
        color: $dark;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .box-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            h2{
                color: $primary;
                text-align: left;

                font-size: 3.5rem;
                max-width: 800px;
                font-weight: 900;

                position: relative;
            }
            p{
                max-width: 500px;
            }
        }
    }

    .close-order{
        position: absolute;
        top: 50px;
        left: 50px;

        font-size: 1.4rem;
        color: $dark;
        font-weight: 700;
        cursor: pointer;
        display: block;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 1px solid $dark;
        border-radius: 50%;

        padding: 2px;
        opacity: .4;
    }
}

// Media screen
@media screen and (max-width: 1024px) {
    #orderCustom{
        padding-top: 70px;
        &>div{
            .box-content{
                width: 100%;
                h2{
                    font-size: 3.545rem;
                }
            }
        }
    
        .close-order{
            top: 3%;
            left: 40px;
        } 
    }
}

@media screen and (max-width: 874px) {
    #orderCustom{
        &>div{
            .box-content{
                h2{
                    font-size: 2.5rem;
                    
                }
            }
        }
        
    }
}
@media screen and (max-width: 425px) {
    #orderCustom{
        &>div{
            .box-content{
                h2{
                    font-size: 2.2rem;
                    
                }
            }
        }
    }
}