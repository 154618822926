#home {
  min-height: auto;
  background: $dark;
  padding: 120px 0;
  display: flex;
  align-items: center;
  & > div {
    color: $light;
    h2 {
      margin-bottom: 25px;
    }
    p {
      max-width: 600px;
    }
    .btn_home {
      width: 200px;
      text-align: center;
      margin-top: 45px;
      transition: transform 250ms ease-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

#landing {
  position: relative;

  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  order: -1;
  .banner_video {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    object-fit: cover;
  }
  &::before {
    content: "";
    height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient($secondary, transparent);
  }
  &::after {
    content: "";
    height: 550px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, $dark);
  }
  .icon-angle-down {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 1.5rem;
    color: $light;
    z-index: 10;

    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

.homewrapper {
  background: $dark;
  min-height: 600px;
  order: 2;
  & > div {
    color: $light;
    min-height: 600px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .box-content {
      max-width: 900px;
    }
    .mobaliparis {
      max-width: 800px;
      align-self: flex-start;
      span {
        &:first-child {
          font-size: 5.5rem;
          font-weight: 700;
          line-height: 1;
          text-transform: none;
          word-break: break-word;
          color: #fff;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $primary;
        }
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: $primary;
        opacity: 0.5;
      }
    }
  }
}

.img-box-sapin {
  position: absolute;
  right: 0;
}

//Effect
@keyframes bounce {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(-15px);
  }
}

// Media screen
@media screen and (max-width: 1024px) {
  #landing {
    min-height: 700px;
  }
}

@media screen and (max-width: 768px) {
  .icon-angle-down {
    bottom: 100px !important;
  }
}

@media screen and (max-width: 425px) {
}
