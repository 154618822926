#admin{
    display: flex;
    align-items: stretch;
    background: $light;
    .sidebar{
        height: 100vh;
        width: 350px;
        color: $light;
        position: fixed;
        top: 0;
        left: 0;
        background: $secondary;
        
        border: none;
        &>div{
            display: flex;
            align-items: center;
            flex-direction: column;

            height: 100%;
            padding-top: 45px;
            position: relative;
            .side-menu{
                display: flex;
                flex-direction: column;
                gap: 75px;
                .list-side-menu{
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    .item-side-menu{
                        a{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            padding: 10px;
                            span{
                                font-size: 1.345rem;
                            }
                        }
                        // &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(6) {
                        //     color: rgba($color: $light, $alpha: .08);
                        //     &>a{
                        //         cursor: no-drop;
                        //     }
                        // }
                        &>.active{
                            background: #4B39E3;
                            padding: 10px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }

    .wrapper_admin{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-left: 350px;
        min-height: 100vh;
        padding: 25px 0;
        gap: 35px;
        background: $light;
        .admin-menu{
            background: rgba($color: $secondary, $alpha: .05);
            height: 65px;
            width: 95%;
            border-radius: 5px;
            box-shadow: 0px 0px 4px #ddd;

            display: flex;
            gap: 15px;
            padding: 0 25px;
            .admin-info{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &>div{
                width: 31%;
                display: flex;
                align-items: center;
                span{
                    font-size: .9rem;
                }
                &:nth-of-type(2){
                    flex-grow: 1;
                    justify-content: center;
                    .brand{
                        height: 50px;

                        figure{
                            height: 50px;
                            img{
                                width: 100%;
                                height: 100%;
        
                                object-fit: cover;
                                filter: opacity(.3);
                            }                
                        }
                    }
                }

                &:last-child{
                    justify-content: flex-end;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    &>p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        span{
                            display: inline-block;
                            font-size: 1.0rem;
                           
                        }
                    }
                    .admin-avatar{
                        height: 40px;
                        width: 40px;

                        border-radius: 50%;
                        overflow: hidden;
                        background: $primary;
                        figure{
                            height: 40px;
                            width: 40px;
                            img{
                                height: 100%;
                                width: 100%;

                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }

        .dashboard{
            display: flex;
            gap: 25px;
            width: 95%;
            &>div{
                width: 24%;
                height: 100%;
                &:first-child{
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    min-height: 700px;
                    .box_top-content{
                        background: #F5B694;
                        border-radius: 3px;
                        padding: 25px 35px;
                        height: 250px;
                        color: $light;

                        margin-bottom: 35px;
                        p{
                            max-width: 50%;
                            margin-bottom: 25px;
                        }                                
                    }
                }

                .recents-articles{
                    background: rgba($color: $secondary, $alpha: .05);
                    
                    border-radius: 3px;
                    flex-grow: 1;
                    padding: 25px;
                    position: relative;
                    width: 100%;
                    &>ul{
                        align-items: flex-start;
                        flex-direction: row;
                        width: 100%;
                        
                        li{
                            padding: 15px 0;
                            position: relative;
                            width: 100%;
                            max-width: none;
                            .author-infos{
                                flex-grow: 1;
                                .avatar{
                                    margin-bottom: 10px;
                                }
                                .author{
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    span{
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                            .box_actus_img{
                                height: 200px;
                                order: -1;
                                figure{
                                    height: 200px
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Side-box_admin
.side-box_admin{
    &>ul{
        display: flex;
        flex-direction: column;
        gap: 25px;
        li{
            height: 130px;
            width: 100%;
            border-radius: 2px;
            color: $secondary;

            display: flex;
            &>div{
                width: 31%;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon_actus-element{
                    background: rgba($color: $secondary, $alpha: .4);
                    height: 70px;
                    width: 70px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;
                    color: $light;
                }
                &:last-child{
                    flex-grow: 1;
                    flex-direction: column;

                    align-items: flex-start;
                    &>span{
                        font-size: 1.3rem;
                        font-weight: 700;
                    }
                }
            }
            &:nth-of-type(1){
                background:#7ECFF1;
            }
            &:nth-of-type(2){
                background:#F1D97E;
            }
            &:nth-of-type(3){
                background:#4B4DFC;
            }
        }
    }
    
}

#all_articles-admin{
    min-height: 400px;
    width: 100%;
    &>div{
        .content-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;
        }
        &>ul{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr ;
            width: 100%;
            li{
                padding: 15px 10px;
                position: relative;
                max-width: none;
                min-height: 200px;
                .author-infos{
                    flex-grow: 1;
                    .avatar{
                        margin-bottom: 10px;
                    }
                    .author{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        span{
                            font-size: 1.1rem;
                        }
                    }
                }
                .box_actus_img{
                    height: 200px;
                    order: -1;
                    display: none;
                    figure{
                        height: 200px
                    }
                }
            }
        }
    }
}

//Create new article page design 
#create-post{
    width: 95%;
    border-radius: 3px;
    &>div{
        form{
            display: flex;
            gap: 25px;
            justify-content: space-between;
            &>div{
                width: 31%;
                .form-group{
                    width: 100%;
                    height: 70px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    &::before{
                        display: none;
                    }
                    .form-control{
                        height: 100%;
                        width: 100%;
                        border:none;
                        background: transparent;
                        font-family: Poppins;
                        &:focus{
                            outline: none;
                            font-family: Poppins;
                        }
                        &::placeholder{
                            font-family: Poppins;
                        }
                    }
                }

                &:first-child{
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;

                    align-items: center;
                    
                }

                &:last-child{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                    &>ul{
                        display: flex;
                        flex-direction: column;
                        gap:25px;
                        li{
                            padding: 25px;
                            background: rgba($color: $dark, $alpha: .03);
                            border-radius: 3px;
                            position: relative;
                            overflow: hidden;
                            p{
                                color: $dark;
                                opacity: 1;
                                margin-bottom: 15px;
                            }
                            .form-control[name="picture"]{
                                color: transparent;
                                height: 30px;
                                &::-webkit-file-upload-button{
                                    visibility: hidden;
                                }
                                &::before{
                                    content: 'Définir l\'image de fond';
                                    color: $primary;
                                    text-decoration: underline;
                                    display: inline-block;
                                    outline: none;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                            }
                            .setting_publication{
                                display: flex;
                                gap: 5px;
                                justify-content: space-between;
                                align-items: center;
                                background: #ECEBEB;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                padding: 5px;
                                
                            }
                            &:first-child{
                                min-height: 150px;

                                input[type=checkbox]{
                                    height: 0;
                                    width: 0;
                                    visibility: visible;
                                }
                                  
                                label {
                                    cursor: pointer;
                                    text-indent: -9999px;
                                    width: 30px;
                                    height: 15px;
                                    background: grey;
                                    display: block;
                                    border-radius: 100px;
                                    position: relative;
                                }
                                  
                                label:after {
                                    content: '';
                                    position: absolute;
                                    top: 2px;
                                    left: 5px;
                                    width: 10px;
                                    height: 10px;
                                    background: #fff;
                                    border-radius: 25px;
                                    transition: 0.3s;
                                }
                                  
                                input:checked + label {
                                    background:$primary;
                                }
                                  
                                input:checked + label:after {
                                    left: calc(100% - 5px);
                                    transform: translateX(-100%);
                                }
                                  
                                label:active:after {
                                    width: 10px;
                                }
                                .input_status{
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                    span{
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
                                    }
                                    &>div{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                    
                } 
            }

            .left-box_form{
                .form-control[name="title"]{
                    font-size: 2.5rem;
                    font-weight: 700;
                    
                    &:focus, &::placeholder{
                        font-size: 2.5rem;
                        font-weight: 700;
                    }
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
                // .box-img{
                //     background: rgba($color: $dark, $alpha: .07);
                //     height: 600px;
                //     width: 440px;
                //     border-radius: 3px;
                //     margin: 25px 0;
                //     position:relative;
                //     overflow: hidden;
                //     figure{
                //         height: 600px;
                //         width: 440px;
                //         img{
                //             height: 100%;
                //             width: 100%;

                //             object-fit: cover;
                //         }
                //     }
                // }
            }
        }
    }
}

#users{
    min-height: 400px;
    width: 100%;
    &>div{
        min-height: 400px;
        .box_top-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

// Profil
#profil{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px 35px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    &::before{
        content: "";
        height: 1px;
        position: absolute;
        top: 0;
        left: 50%;

        transform: translate(-50%, 0);
        background: rgba($color: $light, $alpha: .2);
        width: 220px;
    }
}

.tables{
    width: 100%;
    box-shadow: 0 2px 5px rgba($color: $dark, $alpha: .06);
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    thead{
        box-shadow: 0 5px 10px rgba($color: $dark, $alpha: .06);
        th{
            padding: 1rem 2rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-size: .7rem;
            color: $dark;
            font-weight: 900;
            font-family: Poppins;
        }
    }
    tr{
        td{
            padding: 1rem 2rem;
            .link_article{
                color: #4B39E3;
                &:hover{
                    color: rgba($color: #4B39E3, $alpha: .4);
                }
            }
        }
        .role{
            border-radius: 2px;
            border: none;
            text-align: center;
            padding: .2rem 1rem;
        }
        .category, .status{
            border-radius: 2px;
            border: none;
            text-align: center;
            padding: .2rem .7rem;

            margin-left: 10px;
            font-size: .8rem;
            &:first-child{
                margin-left: 0px;
            }
        }
        .category{
            background: rgba($color: $dark, $alpha: .05);
        }
        &:nth-child(even){
            background: rgba($color: $dark, $alpha: .03);
        }
    }
    
}

// @media screen and (max-width: 2560px) {
//     main{
//         max-width: 2210px;
//     }
// }

// @media screen and (max-width: 1440px) {
//     main{
//         max-width: 1090px;
//     }
// }

// @media screen and (max-width: 1024px) {
//     main{
//         max-width: 674px;
//     }
// }

