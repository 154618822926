.alert-success {
    background: $success;
}
.alert-warning {
    background: $warning;
}
.alert {
    padding: 15px 10px;
    border-radius: 5px;
    color: $dark;
    width: 600px;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 5px;
    span{
        color: $dark;
        display: flex;
        color: $dark;

        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: .945rem;
        &>button{
            background: none;
            border: none;
            font-size: 1.1rem;
            cursor: pointer;
        }
    }
}