#landing_notfound{
    position: relative;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/banner_home.png);

    min-height: 800px;
    width: 100%;
    overflow: hidden;
    order: -1;
    &::before{
        content: "";
        height: 400px;
        width: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient($dark, transparent);
    }
    &::after{
        content: "";
        height: 550px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(transparent, $dark);
    }

    .not_found{
        position: absolute;
    
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
    
        min-width: 400px;
        z-index: 10;
        color: $light;

        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 45px;
            span{
                display: block;
                font-weight: 800;
                font-size: 4.0rem;
            }
        }
        .link_notfound{
            text-align: center;
            
            height: 45px;
            width: 45px;
            border-radius: 50px;
            cursor: pointer;
            background: $primary;
            overflow: hidden;
            border:none;
            &>span{
                color: $light;
                font-weight: 700;
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
            }
        }
    }
}

.error-message{
    background: #fff9c4;
    color: #7a6e08;

    height: 70px;
    border-radius: 5px;
    min-width: 500px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.boxError{
    background: #fcdddd;
    color: #f3231f;

    height: 70px;
    border-radius: 5px;
    min-width: 500px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    z-index: 10;
}