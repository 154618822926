#footer{
    padding: 45px 0;

    background: $dark;
    &>div{
        color: $light;
        display: flex;
        flex-direction: column;
        position: relative;
        .list_social{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: 0 auto;
            gap: 15px;
            width: 100%;
            text-align: left;
            margin-bottom: 10px;
            li{
                text-align: center;
            }
        }
        .list_link_copyright{
            display: flex;
            justify-content: space-between;
            li{
                &:last-child{
                    display: flex;
                    gap: 15px;
                }
            }
        }
    }
}
.list_social{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 15px;
}
.effect_hover{
    position: relative;
    &:before{
        content: "";
        height: 2px;
        width: 0%;

        position: absolute;
        bottom: 0;
        left: 0;


        background: $primary;
        transition: all 350ms ease-in-out;
    }
    &:hover:before{
        width: 100%;
    }
}

// Media screen
@media screen and (max-width: 1024px) {
    
}

@media screen and (max-width: 425px) {
    #footer{
        min-height: 90px;
        &>div{
            min-height: 90px;
            padding: 0px 0;
            flex-direction: column;
            .box-content{
                text-align: center;
                margin-bottom: 45px;
            }
            .list_social{
                align-items: center;
                justify-content: center;
                gap: 15px;
                margin-bottom: 15px;
            }
            .list_link_copyright{
                display: flex;
                flex-direction: column;
                li{
                    text-align: center;
                    &:last-child{
                        justify-content: center;
                    }
                }
            }
        }
    }
}