#promo {
  min-height: 500px;
  position: relative;

  background: #151314;
  padding: 170px 0 45px 0;

  & > div {
    min-height: 500px;
    color: $light;
    .cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 35px;
      margin-bottom: 75px;
      .box {
        width: 41%;
        gap: 25px;
        &:last-child {
          flex-grow: 1;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .content {
        h2 {
          position: relative;
          span {
            position: absolute;
            height: 15px;
            width: 3px;
            border-radius: 25px;
            background: $primary;
            top: -15px;
            left: -5px;
            rotate: -25deg;
            &:before,
            &:after {
              content: "";
              height: 15px;
              width: 3px;
              border-radius: 25px;
              background: $primary;
              position: absolute;
            }
            &:before {
              right: -15px;
              rotate: 25deg;
            }
            &:after {
              right: 15px;
              rotate: -25deg;
            }
          }
        }
      }
      .img-box {
        position: relative;
        width: 70%;
        figure {
          img {
            width: 100%;
            height: 70%;

            object-fit: cover;
            border-radius: 2px;
            filter: brightness(0.5);
          }
        }
      }
    }
  }
}

#mes-presta {
  min-height: 800px;
  background: #222124;
  padding-top: 45px;
  color: $light;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 75px;
    & > div {
      width: 31%;
    }
    .box-content {
      .content_preta {
        text-align: left;
        position: relative;
        h2 {
          color: $light;
          position: relative;
          span {
            position: absolute;
            height: 15px;
            width: 3px;
            border-radius: 25px;
            background: $primary;
            top: -15px;
            left: -5px;
            rotate: -25deg;
            &:before,
            &:after {
              content: "";
              height: 15px;
              width: 3px;
              border-radius: 25px;
              background: $primary;
              position: absolute;
            }
            &:before {
              right: -15px;
              rotate: 25deg;
            }
            &:after {
              right: 15px;
              rotate: -25deg;
            }
          }
        }
        & > p {
          max-width: 825px;
          margin: 0 auto;
          span {
            display: block;
            margin-top: 15px;
          }
        }
      }
    }

    .box_preta {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      order: -1;
      .list_preta {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        li {
          width: 41%;
          margin-bottom: 25px;
          padding: 0 25px;
          border-radius: 10px;
          position: relative;

          // &:before {
          //   content: "";
          //   height: 100%;
          //   width: 2px;
          //   background: $light;
          //   opacity: 0.08;
          //   position: absolute;
          //   top: 0;
          //   right: 0;
          // }
          &:nth-of-type(4):before {
            display: none;
          }
          h3 {
            position: relative;
          }
          .icon-presta {
            background: rgba($color: $primary, $alpha: 0.2);
            border: 1px solid rgba($color: $primary, $alpha: 0.5);
            border-radius: 50%;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            color: $primary;
          }
        }
      }
    }
  }
}

#section-service_trois {
  position: relative;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../images/catalogue.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.4;
  }
  &:after {
    content: "";
    height: 250px;
    width: 100%;
    background: linear-gradient($dark, transparent);
    position: absolute;
    top: -45px;
    left: 0;
  }

  & > div {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    & > div {
      width: 41%;
      position: relative;
      padding: 90px 0;
      h2 {
        color: $light;
        font-size: 6.5rem;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          display: block;
          color: $primary;
          margin-left: 55px;
        }
      }
      p {
        color: $primary;
        font-weight: 500;
        font-size: 1.6rem;
      }
      .box-img {
        height: 800px;
        width: 800px;
        position: absolute;
        top: 15%;
        left: 70%;
        transform: translate(-50%, 0%);
        figure {
          height: 800px;
          width: 800px;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
      &:first-child {
        order: 1;
        .date-cat {
          color: $dark;
          font-size: 3rem;
          font-weight: 700;
          position: absolute;
          bottom: 20%;
          right: 20%;
          transform: translate(-50%, -50%);
        }
        .by {
          color: $dark;
          font-size: 1.2rem;
          font-weight: 400;
          position: absolute;
          bottom: 5%;
          right: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .fidownload {
    background: rgba($color: $primary, $alpha: 0.8);
    border: none;
    color: $light;
    z-index: 1;

    font-size: 2rem;
    border: 2px double $primary;

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    box-shadow: 0px 2px 3px 3px $primary;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  #promo {
    padding-top: 120px;
    padding-bottom: 45px;
    min-height: 600px;
    & > div {
      div {
        width: 100%;
        .box_img_promo {
          width: 350px;
          margin: 0 auto;
          figure {
            width: 350px;
            &::before {
              height: 350px;
            }
          }
        }
      }
      .left {
        .before_img1 {
          left: -25px;
          width: 25px;
        }
        h1 {
          width: 100%;
          margin-bottom: 45px;
          text-align: center;
        }
      }
      .right {
        .box_img_promo {
          margin-bottom: 25px;
          .before_img2 {
            left: -45px;
            top: -10px;

            width: 100px;
            height: 100px;
          }
        }
        p {
          margin-bottom: 6px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #promo {
    padding: 120px 0 25px 0;
    &:before {
      width: 90%;
    }
    & > div {
      gap: 25px;
      .cont {
        .box {
          width: 100%;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h2 {
              width: 100%;
            }
            p {
              width: 100%;
            }
          }

          .img-box {
            height: 550px;
            width: 100%;
            &:before,
            &:after {
              display: none;
            }
            figure {
              height: 550px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  #mes-presta {
    padding-top: 30px;
    padding-bottom: 30px;
    &:before {
      display: none;
    }
    .wrapper {
      & > div {
        width: 100%;
      }
      .content-box {
        .box-content {
          h2 {
            span {
              color: $primary;
              font-weight: 500;
              margin-bottom: 15px;
            }
          }
        }
      }

      .box_preta {
        order: 1;
        .list_preta {
          li {
            width: 100%;
          }
        }
      }
    }
  }

  #section-service_trois {
    position: relative;
    &:after {
      height: 100px;
      display: none;
    }

    & > div {
      overflow: hidden;
      & > div {
        width: 100%;
        padding: 45px 0;
        h2 {
          font-size: 4rem;
          position: relative;
          margin-bottom: 25px;
          text-align: center;
          span {
            margin-left: 0px;
          }
        }
        p {
          color: $primary;
          font-weight: 500;
          font-size: 1.6rem;
        }
        .box-img {
          height: 400px;
          width: 400px;
          position: relative;
          top: 15%;
          left: 0;
          transform: translate(0, 0);
          figure {
            height: 400px;
            width: 400px;
          }
        }
        &:first-child {
          order: -1;
          .date-cat {
            font-size: 1.1rem;
            font-weight: 500;
            position: relative;
            bottom: 0;
            right: 0;
            transform: translate(0, 0);
            text-align: center;
          }
          .by {
            display: none;
          }
        }
        &:last-child {
          & > p {
            display: none;
          }
        }
      }
    }
    .fidownload {
      height: 70px;
      width: 70px;
    }
  }
}

@media screen and (max-width: 425px) {
  #promo {
    & > div {
      .img-box {
        height: 350px;
        figure {
          height: 350px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  #section-service_trois {
    position: relative;
    &:after {
      height: 100px;
    }

    & > div {
      & > div {
        h2 {
          font-size: 3rem;
        }
      }
    }
  }

  #mes-presta {
    padding-top: 30px;
    padding-bottom: 30px;
    & > div {
      .content_preta {
        max-width: 100%;
        h2 {
          span {
            color: $primary;
            font-weight: 500;
            margin-bottom: 15px;
          }
        }
      }

      .box_preta {
        .list_preta {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}
