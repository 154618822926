//Tailwind directives
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "Variables";
@import "Header";
@import "Dashboard";
@import "Home";
@import "About";
@import "Creator";
@import "Services";
@import "Project";
@import "Login";
@import "Contact";
@import "Forms";
@import "Alert";

@import "../../ui/button/assets/Button.scss";
@import "../../features/actus/assets/Actus";

@import "Error";
@import "Effect";
@import "Footer";

html,
body {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  background: $dark;
  scroll-behavior: smooth;
}
.dancingfont{
  font-family: "Dancing", "sans-serif";
}
html {
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none; /* Pour Internet Explorer 10+ */
}

body::-webkit-scrollbar {
  display: none; /* Pour Chrome, Edge et Safari */
}
#login {
  color: $light;
}
#root {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    height: auto;
  }
  main {
    flex-grow: 1;
  }
  footer {
    height: auto;
  }
}

#container {
  display: flex;
  flex-direction: column;
}

.layoutactus {
  display: flex;
  min-height: 100vh;
  position: relative;
  main {
    width: 31% !important;
    flex-grow: 1;
    border: 1px solid $light;
  }
}

.markdown-preview {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  white-space: pre-wrap;
  width: 100%;
  height: 200px;
}
/*
* En commun
**/

.wrapper {
  width: 80%;
  margin: 0 auto;
}
.wrapper_admin {
  width: 1440px;
  margin: 0 auto;
}
a {
  text-decoration: none;
  list-style-type: none;
  color: inherit;
  transition: color 0.4s ease-in-out;
}

strong {
  font-weight: 700;
}

.primary {
  color: $primary;
}
.title_h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px;

  opacity: 0.9;
}

.title_h2 {
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.title_h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 7px;
}
.title-h2 {
  margin-bottom: 15px;
  margin-top: 35px;
}
.text {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 1.6;
  opacity: 0.7;
}

.traits{
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    height: 100px; /* Ajuste selon ton besoin */
    width: 2px;
    background-color: rgba($color: $dark, $alpha: .3); /* Remplace $dark par la couleur souhaitée */
    position: absolute;
    top: -100%;
    left: 50%; /* Centre le trait par rapport à l'élément */
    transform: translateX(-50%); /* Corrige l'alignement horizontal */
    z-index: -1;
  }
}
  

.line_hover {
  position: relative;
  &:before {
    content: "";
    height: 2px;
    width: 0%;

    position: absolute;
    bottom: -5px;
    left: 0;

    background: $primary;
    transition: all 350ms ease-in-out;
    opacity: 0;
  }
  &:hover:before {
    width: 100%;
    opacity: 1;
  }
}

.img_link {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: 5;
}

.back_to_actus_page {
  position: fixed;
  top: 145px;
  left: 200px;
  height: 40px;
  width: 40px;
  background: $primary;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  span {
    font-size: 1.4rem;
    color: $light;
    transition: all 250ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobaliparis {
  max-width: 800px;
  align-self: flex-start;
  span {
    &:first-child {
      font-size: 5.5rem;
      font-weight: 700;
      line-height: 1;
      text-transform: none;
      word-break: break-word;
      color: #fff;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $primary;
    }
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: $primary;
    opacity: 0.5;
  }
}

.customer {
  padding: 25px 0;
  gap: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  li {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $light;
    width: 21%;
    margin-bottom: 35px;
    figure {
      height: auto;
      width: auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        filter: opacity(1);
      }
    }
  }
}

.logo-colab {
  height: auto;
  figure {
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.slider {
  margin: 0 auto;
  background-color: none;
  .slide-track {
    width: 100%;
    display: flex;
    gap: 5em;
    overflow: hidden;
    .slide {
      figure {
        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
          animation: scroll 60s linear infinite;
        }
      }
    }
  }
}

#component-one {
  padding-top: 70px;
  position: relative;
  min-height: 700px;
  overflow: hidden;
  & > div {
    min-height: 700px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    position: relative;
    .content {
      width: 31%;
      margin-bottom: 35px;
      flex-grow: 1;
      & > p {
        max-width: 825px;
        margin: 0 auto;
        span {
          display: block;
          margin-top: 10px;
        }
      }
    }
    .mobaliparis {
      max-width: 800px;
      align-self: flex-start;
      span {
        &:first-child {
          font-size: 5.5rem;
          font-weight: 700;
          line-height: 1;
          text-transform: none;
          word-break: break-word;
          color: #fff;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $primary;
        }
        display: block;
        font-size: 1rem;
        font-weight: 400;
        color: $primary;
        opacity: 0.5;
      }
    }
    .videohome {
      order: -1;
      height: 500px;
      position: relative;
      &:before {
        content: "";
        height: 510px;
        width: 100%;
        position: absolute;
        left: -15px;
        z-index: -1;
        top: 5px;
        background: $primary;
      }
      video {
        height: 500px;
        border-radius: 5px;
      }
    }
  }

  .img-box {
    position: absolute;
    bottom: -50px;
    right: -200px;
    height: 800px;
    figure {
      height: 800px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.trait {
  position: absolute;
  height: 15px;
  width: 3px;
  border-radius: 25px;
  background: $primary;
  top: -15px;
  left: -5px;
  rotate: -25deg;
  &:before,
  &:after {
    content: "";
    height: 15px;
    width: 3px;
    border-radius: 25px;
    background: $primary;
    position: absolute;
  }
  &:before {
    right: -15px;
    rotate: 25deg;
  }
  &:after {
    right: 15px;
    rotate: -25deg;
  }
}
.linkcolor {
  color: $primary;
}

.setting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
}

#avatar {
  height: 100px;
  width: 100px;
  overflow: hidden;
  figure {
    height: 100px;
    width: 100px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: contain;
    }
  }
}

#mentions {
  min-height: 100vh;
  padding: 110px 0;
  & > div {
    color: $light;
    max-width: 900px;
    h2 {
      text-decoration: underline $primary;
    }
    ol {
      list-style-type: decimal;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      li {
        h3 {
          font-weight: 400;
          font-size: 1.1rem;
        }
        .proprietaire {
          margin-top: 45px;
          & > p {
            border: 1px solid rgba($color: $light, $alpha: 0.4);
            border-radius: 2px;
            padding: 15px;

            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
.mth {
  margin-bottom: 10px !important;
}

.hover {
  transition: 250ms ease;
  &:hover {
    transform: scale(1.1);
    color: $primary;
  }
}

// Users
#profil-user {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  span {
    color: $light;
    &:first-child {
      font-family: Poppins;
      font-size: 1.2rem;
    }
  }
}

.boxprofil {
  background: $light;
  position: absolute;
  height: auto;
  width: 227px;
  right: 0;
  top: 90px;
  border-radius: 25px;
  padding: 15px;
  & > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      position: relative;
      height: 45px;
      padding: 10px;
      &::before {
        content: "";
        height: 1px;
        width: 100%;
        background: rgba($color: $dark, $alpha: 0.2);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:last-child:before {
        display: none;
      }
      a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
      &:last-child {
        color: #e64747;
      }
    }
    .disabled {
      color: rgba($color: $dark, $alpha: 0.6);
      a {
        cursor: not-allowed;
      }
    }
  }
}

//CKEDITOR
.ckeditor {
  width: 100%;
}

//Jodit react
.jodit-react-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiperLogo .swiper-wrapper{
  display: flex;
  align-items: center;
}
.swiper-pagination-bullet{
  background: rgba($light, .7);
}
.swiper-button-disabled {
  color: rgba($light, .7) !important;
}
.swiper-pagination-bullet-active{
  background: #fafafa;
}

.swiper-button-prev, .swiper-button-next {
  color: #fafafa !important;
  z-index: 10;
}



/* Cache les barres de défilement pour les navigateurs compatibles */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Pour Internet Explorer et Edge */
  scrollbar-width: none; /* Pour Firefox */
}

//Lenis
.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-10000%);
  }
}

.scroller[data-animated="true"] {
  overflow: hidden !important;
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}
@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

// Media screen
@media screen and (max-width: 1440px) {
  .wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .scrollbar-hidden::-webkit-scrollbar {
    display: block;
  }

  #landing_notfound {
    min-height: 500px;
    &::before {
      height: 300px;
    }
    &::after {
      height: 400px;
    }
    & > div {
      min-height: 700px;
      position: relative;
      .banner_home_content {
        width: 100%;
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .title_h1 {
    font-size: 1.958rem;
  }

  .title_h2 {
    font-size: 1.6rem;
  }

  .title_h3 {
    font-size: 1.3rem;
  }
  .text {
    font-size: 0.9rem;
  }

  // ActusOld
  .back_to_actus_page {
    top: 25px;
    left: 25px;
  }
  //Components
  #component-one {
    min-height: 500px;
    & > div {
      width: 90%;
      .videohome {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  body.hidden {
    overflow: hidden;
  }
  #landing_notfound {
    &::before {
      height: 200px;
    }
    &::after {
      height: 350px;
    }
  }
  #customer {
    & > div {
      .list_logo_customer {
        gap: 7px;
        & > li {
          width: 31%;
          margin-bottom: 15px;
          & > p {
            margin-bottom: 15px;
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  // En commun
  #landing_notfound {
    background-image: url(../images/banner_home_mobile.png);
  }

  .title_h1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .title_h2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }

  .title_h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  .customerid {
    width: 100%;
    .customer {
      width: 100%;
      & > div {
        .list_logo_customer {
          gap: 7px;
          & > li {
            display: flex;
            flex-direction: column;
            align-items: center;

            color: $light;
            width: 100%;
            & > p {
              margin-bottom: 15px;
              font-size: 1rem !important;
              text-decoration: underline;
            }
            .listCust {
              li {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
