.guirlande {
    pointer-events: none;
    z-index: 10;
    width: 100%;
    height: 150px;
    position: absolute;
    top: 150px;
    left: 0;
    &:nth-of-type(1){
        height: 120px;
        background: url(../images/guirlande1.png) repeat-x 300% top;
        top: -23px;
    }
    &:nth-of-type(2){
        background: url(../images/guirlande2.png) repeat-x 70% top;
        top: -71px;
    }
    &:nth-of-type(3){
        background: url(../images/guirlande3.png) repeat-x 10% top;
        top: -50px;
    }
}

.snow-wrapper{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    min-height: 800px;
    overflow: hidden;
    .snow{
        &::before, &::after{
            content: "";
            position: absolute;
            top: -650px;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: 
            radial-gradient(4px 4px at 100px 50px, $light, transparent),
            radial-gradient(6px 6px at 200px 150px, $light, transparent),
            radial-gradient(3px 3px at 300px 250px, $light, transparent),
            radial-gradient(4px 4px at 400px 350px, $light, transparent),
            radial-gradient(6px 6px at 500px 100px, $light, transparent),
            radial-gradient(3px 3px at 50px 200px, $light, transparent),
            radial-gradient(4px 4px at 150px 300px, $light, transparent),
            radial-gradient(6px 6px at 250px 400px, $light, transparent),
            radial-gradient(3px 3px at 350px 500px, $light, transparent);
            background-size: 650px 650px;

            animation: snowAnim 3s linear infinite;
            animation-iteration-count: infinite;
        }
        &::after{
            margin-left: -250px;
            opacity: .5;
            filter: blur(2px);
        }
        &::before{
            margin-left: -350px;
            opacity: .8;
            filter: blur(1px);
        }
    }
}

// Bubbles effect

  
@keyframes snowAnim {
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(650px);
    }
}