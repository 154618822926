.forms{
    width: 100%;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    align-items: center;
    margin-top: 25px;
    overflow: hidden;
    
    border-radius: 35px;
    .group-forms{
        display: flex;
        width: 100%;
        gap: 12px;
        &>div{
            width: 50%;
            
        }
    }
    .designhrp{
        position: relative;
        font-size: 1.4rem;

        margin: 45px 0px;
        &::before{
            content: "";
            height: 1px;
            width: 300px;
            opacity: .3;
            background: $dark;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translate(0%,-50%);
        }
        &::after{
            content: "";
            height: 1px;
            width: 300px;
            opacity: .3;
            background: $dark;
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translate(0%,-50%);
        }
    }
}
.radiobox{
    background: #FBFBFB;
    width: 150px;
    border-radius: 5px;
    padding: 10px;
    &>div{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}
.form-group{
    width: 100%;
    
    height: 55px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    &::before{
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba($color: $dark, $alpha: .5);
        opacity: .245;
    }
    .form-control{
        height: 100%;
        width: 100%;
        border:none;
        background: transparent;
        font-family: Poppins;
        &:focus{
            outline: none;
            font-family: Poppins;
        }
        &::placeholder{
            font-family: Poppins;
        }
    }
}

.focused::before{
    background-color: rgba($color: $primary, $alpha: 1);
}

.form-group-area{
    width: 100%;
    margin-top: 35px;
    border: 1px dashed rgba($color: $dark, $alpha: .2);
    padding: 10px;
    border-radius: 15px;
    textarea{
        border: none;
        width: 100%;
        height: 100px;
        background: none;
        resize: none;
        overflow: auto;
        font-family: Poppins;
        &:focus{
            outline: none;
        }
    }
}

.type{
    width: 100%;
    p{
        text-align: center;
        margin: 25px 0;
    }
    &>div{
        display: flex;
        gap: 25px;
    }
}

// flex box element
.boxForm{
    width: 100%;
    transition: all 250ms linear;
    h3{
        font-weight: 900;
        font-size: 1.2rem;
        opacity: .8;
    }
    .form-flex{
        display: flex;
        gap: 15px;
        align-items: center;
    }
    .radiobox{
        display: flex;
        flex-wrap: wrap;
        
        gap: 25px;
        
        width: 100%;
        &>div{
            width: 21%;
            position: relative;
            label{
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;
                cursor: pointer;
            }
            input{
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translate(-50%, -50%);

                
            }
            /* Style lorsque le radio est coché */
            input[type="radio"]:checked {
                /* Styles spécifiques lorsque le radio est coché */
                background-color: $primary !important; /* Exemple de style, ajustez selon vos besoins */
                color: white;
            }
        }
    }
}

.custom-checkbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    margin-bottom: 5px;
    &:last-child{
        margin-bottom: 0px;
    }
    &>input[type="checkbox"] {
        position: relative;
        width: 1em;
        height: 1em;
        color: $dark;
        border: 1px solid rgba($color: $dark, $alpha: .2);
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &:checked {
        color: $light;
        border-color: $primary;
        background: $primary;
        &::before {
            opacity: 1;
        }
        ~ label::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
        }
    }

    &>label {
        position: relative;
        cursor: pointer;
        font-size: 1.0em;
        user-select: none;
        &::before {
          position: absolute;
          content: attr(data-content);
          color: rgba($color: $dark, $alpha: .2);
          clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
          text-decoration: line-through;
          text-decoration-thickness: 3px;
          text-decoration-color: $dark;
          transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
    }
}

::placeholder{
    color: rgba($color: $dark, $alpha: .6);
    font-family: Poppins;
}

input{
    border: none;
    &:focus{
        outline: none;
    }
}

@media screen and (max-width: 850px) {
    .forms{
        padding: 15px;
        background: transparent;
        .designhrp{
            position: relative;
            font-size: 1.4rem;
    
            margin: 45px 0px;
            &::before, &::after{
                width: 150px;
            }
        }
    }

    .boxForm{
        .radiobox{
            &>div{
                width: 31%;
                position: relative;
            }
        }
    }
    
}

@media screen and (max-width: 427px) {
    .boxForm{
        .radiobox{
            &>div{
                width: 44%;
            }
        }
    }
    
}