#actus-layout {
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: stretch;
}

#actus {
  padding-top: 110px;
  padding-bottom: 70px;
  color: $light;
  background: #151314;
  min-height: 100vh;
  position: relative;
  & > div {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    position: relative;
    .actus-content {
      width: 21%;
      position: fixed;
      top: 0;
      left: 0;
      margin-bottom: 55px;
      border-right: 1px solid rgba($color: $light, $alpha: 0.2);
      height: 100%;
      padding: 45px;
      .infos-header {
        display: flex;
        align-items: center;
        gap: 25px;
        margin-bottom: 25px;
        & > div {
          .link-infos {
            display: flex;
            gap: 15px;
            align-items: center;
          }
          &:last-child {
            order: -1;
            .img-box {
              height: 74px;
              width: 74px;
              overflow: hidden;
              &:first-child {
                content: "";
                height: 80px;
                width: 80px;
                border-radius: 50%;

                background: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              figure {
                height: 74px;
                width: 74px;
                img {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
      .infos-agency {
        position: relative;
        min-height: 170px;
        .p1 {
          opacity: 1 !important;
        }
        .p2 {
          font-size: 0.745rem;
        }
        .list-agency {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 15px;
          li {
            position: relative;
            padding: 0 9px;
            &:before {
              content: "";
              height: 50%;
              width: 2px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0%, -50%);
              background: $light;
            }
            &:first-child {
              padding-left: 0px;
              &:before {
                display: none;
              }
            }
          }
        }
        hr {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          height: 2px;
          opacity: 0.5;
        }
      }
    }
    .float_box {
      position: absolute;
      right: 0;
      width: 21%;
      p {
        margin-bottom: 15px;
      }
    }
  }
  .backtosite {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 55px;
    border-bottom: 1px solid rgba($color: $light, $alpha: 0.2);

    display: flex;
    align-items: center;
    justify-content: space-around;
    .back_ {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translate(-50%, -50%);
      font-weight: 700;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#en_ce_momment {
  padding: 90px 0;
  background: #151314;
  min-height: 600px;
  position: relative;
  overflow: hidden;
  //&:before {
  //  content: "";
  //  height: 90px;
  //  width: 550px;
  //  border-bottom-left-radius: 50px;
  //  position: absolute;
  //  top: -35px;
  //  right: 0;
  //  rotate: -5deg;
  //  background: #000;
  //}
  & > div {
    color: $light;
    min-height: 600px;
    position: relative;
    h2 {
      margin-bottom: 55px;
      span {
        color: $primary;
      }
    }
    button {
      background: rgba($color: $light, $alpha: 0.08);
      border: none;
      height: 45px;
      width: 150px;
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 500;
      color: $light;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      gap: 10px;
      margin-top: 45px;

      left: 0;
      bottom: 0;
      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.actus_list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
  width: 41%;
  .card_actus {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    .author-infos {
      display: flex;
      flex-direction: column;
      h3 {
        font-weight: 500;
      }
      p {
        font-size: 0.9rem;
      }
      margin-bottom: 15px;
    }

    .box_actus_img {
      overflow: hidden;
      height: 600px;
      max-width: 500px;
      margin-bottom: 15px;
      position: relative;
      border-radius: 2px;
      figure {
        height: 600px;
        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          border-radius: 2px;
          filter: brightness(0.7);
        }
      }
      transition: 500ms ease-in-out;
      &:hover {
        .setting {
          display: flex;
        }
      }
    }

    &:first-child {
      flex-grow: 1;
      .content_actus {
        h3 {
          font-size: 1.454rem;
        }
      }
    }

    .content_actus {
      display: flex;
      gap: 25px;
      p {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        transition: color 250ms ease-in;
        span {
          height: 30px;
          width: 30px;

          border-radius: 50%;
          font-size: 1.2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 2px;
          transition: background 250ms ease-in;
          cursor: pointer;
          &:hover {
            background: rgba($color: $primary, $alpha: 0.2);
          }
        }
        &:hover {
          color: rgba($color: $primary, $alpha: 1);
        }
      }
    }
  }
}

//Avatar design
.avatar {
  order: -1;
  display: flex;
  align-items: center;
  gap: 10px;
  .img-span-box {
    height: 45px;
    width: 45px;
    overflow: hidden;
    display: block;
    figure {
      height: 45px;
      width: 45px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        border-radius: 50%;
      }
    }
  }
  span {
    color: $primary;
  }
}

.actus_list_home {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  justify-content: space-between;
  & > .card_actus {
    display: flex;
    width: 27%;
    flex-direction: column;
    position: relative;
    .author-infos {
      order: 1;
      display: flex;
      flex-direction: column;
      h3 {
        font-weight: 400;
        margin-bottom: 15px;
      }
      p {
        font-size: 0.9rem;
      }
      margin-bottom: 15px;
    }
    .box_actus_img {
      overflow: hidden;
      height: 500px;
      margin-bottom: 15px;
      position: relative;
      border-radius: 2px;
      figure {
        height: 500px;
        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          border-radius: 2px;
          filter: brightness(0.7);
        }
      }
    }

    &:first-child {
      flex-grow: 1;
      .content_actus {
        h3 {
          font-size: 1.454rem;
        }
      }
      .author-infos {
        h3 {
          font-size: 1.68rem;
          font-weight: 700;
          letter-spacing: 1px;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
    .content_actus {
      display: flex;
      gap: 25px;
      p {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        transition: color 250ms ease-in;
        span {
          height: 30px;
          width: 30px;

          border-radius: 50%;
          font-size: 1.2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 2px;
          transition: background 250ms ease-in;
          cursor: pointer;
          &:hover {
            background: rgba($color: $primary, $alpha: 0.2);
          }
        }
        &:hover {
          color: rgba($color: $primary, $alpha: 1);
        }
      }
    }
  }
}

.card_actus_display {
  min-height: 700px;
  padding-top: 70px;
  padding-bottom: 45px;
  position: relative;
  max-width: 1024px;
  margin: 0 auto;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    color: $light;

    .content_actus {
      text-align: center;
      p {
        span {
          color: $primary;
          margin-right: 2px;
        }
      }
    }
    .box_actus_img {
      max-height: 600px;
      width: 100%;
      margin-bottom: 25px;
      figure {
        overflow: hidden;
        height: 600px;
        img {
          height: 100%;
          width: 100%;
          border-radius: 2px;
          object-fit: contain;
          filter: opacity(0.8);
        }
      }
    }
    .boxcontent {
      width: 100%;
    }
  }
}

.create_at {
  font-size: 0.845rem !important;
  text-decoration: underline;
  margin-bottom: 15px;
}

.lists-stories {
  display: flex;
  justify-content: center;
  gap: 25px;

  margin-bottom: 55px;
  .item-storie {
    border-radius: 50%;
    height: 70px;
    width: 70px;

    overflow: hidden;
    figure {
      height: 70px;
      width: 70px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.boxcontent {
  position: relative;
  margin-bottom: 25px;
  padding: 45px;
  &:before {
    content: "";
    height: 1px;
    width: 100%;
    background: rgba($color: $light, $alpha: 0.2);
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1440px) {
  #actus {
    & > div {
      .actus-content {
        width: 31%;
      }
    }
    .backtosite {
      width: 500px;
    }
  }
  .actus_list {
    width: 34%;
  }
}
@media screen and (max-width: 1024px) {
  #actus {
    padding-top: 110px;
    padding-bottom: 70px;
    & > div {
      .actus-content {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
        border-right: none;
        border-bottom: 1px solid rgba($color: $light, $alpha: 0.2);
        padding: 15px 0px;
        .infos-header {
          gap: 25px;
          margin-bottom: 25px;
          & > div {
            .link-infos {
              display: flex;
              gap: 15px;
              align-items: center;
            }
          }
        }
      }
      .float_box {
        order: 1;
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba($color: $light, $alpha: 0.2);
        margin-bottom: 45px;
        padding: 10px 0px;
        p {
          margin-bottom: 15px;
        }
      }
    }
    .backtosite {
      top: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0%);
    }
  }

  .actus_list {
    order: 2;
    gap: 9px;
    width: 100%;
    .card_actus {
      width: 100%;
    }
  }

  .actus_list_home {
    & > .card_actus {
      width: 21%;
      flex-grow: 1;
      .author-infos {
        h3 {
          font-size: 0.9rem;
          opacity: 0.7;
          span {
            color: $primary;
            margin-right: 2px;
          }
          margin-bottom: 10px;
        }
        .descr {
          display: none;
          visibility: hidden;
        }

        margin-bottom: 15px;
      }
      .box_actus_img {
        height: 200px;
        margin-bottom: 15px;
        border-radius: 1px;
        figure {
          height: 200px;
        }
      }

      &:first-child {
        width: 100%;
        .author-infos {
          .descr {
            display: block;
            visibility: visible;
          }
        }

        h3 {
          font-size: 1.254rem;
        }

        .box_actus_img {
          order: 1;
          height: 400px;
          figure {
            height: 400px;
          }
        }
      }
      .content_actus {
        display: flex;
        gap: 25px;
        p {
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          transition: color 250ms ease-in;
          span {
            height: 30px;
            width: 30px;

            border-radius: 50%;
            font-size: 1.2rem;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 2px;
            transition: background 250ms ease-in;
            cursor: pointer;
            &:hover {
              background: rgba($color: $primary, $alpha: 0.2);
            }
          }
          &:hover {
            color: rgba($color: $primary, $alpha: 1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 842px) {
  .actus_list {
    .card_actus {
      .content_actus h3 {
        text-align: center;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          color: rgba($color: $primary, $alpha: 0.5);
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  #actus {
    padding-top: 110px;
    padding-bottom: 70px;
    & > div {
      .actus-content {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
        border-right: none;
        border-bottom: 1px solid rgba($color: $light, $alpha: 0.2);
        padding: 15px 0px;
        .infos-header {
          gap: 25px;
          margin-bottom: 25px;
          & > div {
            .link-infos {
              display: flex;
              gap: 15px;
              align-items: center;
            }
          }
        }
      }
      .float_box {
        order: 1;
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba($color: $light, $alpha: 0.2);
        margin-bottom: 45px;
        padding: 10px 0px;
        p {
          margin-bottom: 15px;
        }
      }
    }
    .backtosite {
      top: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0%);
    }
  }

  .actus_list {
    gap: 45px;
    .card_actus {
      min-width: 400px;
      .author-infos {
        width: 100%;
        h3 {
          font-size: 1.1rem;
          opacity: 0.9;
        }
      }
    }
  }

  .card_actus_display {
    padding-top: 120px;
    padding-bottom: 30px;
    & > div {
      gap: 10px;
      .content_actus,
      .boxcontent {
        width: 100%;
      }

      .box_actus_img {
        height: 450px;
        figure {
          height: 450px;
          img {
            border-radius: 5px;
          }
        }
      }

      .back_to_actus_page {
        margin-bottom: 15px;
        span {
          font-size: 1rem;
        }
      }
      .boxcontent {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #en_ce_momment {
    padding: 90px 0;
    background: linear-gradient($dark, #0c0c0c);
    min-height: 600px;
    &:before {
      display: none;
    }
    & > div {
      color: $light;
      min-height: 600px;
      h2 {
        margin-bottom: 35px;
      }
    }
  }
}
