#creator{
    position: relative;
    // background-image: url(../images/creator1gris.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 800px;
    padding-top: 190px;
    &::before{
        content: "";
        height: 500px;
        width: 100%;
        background: linear-gradient(#0c0c0c, transparent);

        position: absolute;
        top: 0;
    }
    &::after{
        content: "";
        height: 500px;
        width: 100%;
        background: linear-gradient(transparent, #0c0c0c);

        position: absolute;
        bottom: 0;
    }
    &>div{
        color: $light;
        p{
            max-width: 600px;
            margin-bottom: 55px;
        }
    }
}
//.swiper {
//    width: 100%;
//    max-height: 350px;
//    .swiper-slide {
//        text-align: center;
//        font-size: 18px;
//        background: #fff;
//
//        /* Center slide text vertically */
//        display: flex;
//        flex-wrap: wrap;
//        justify-content: center;
//        align-items: center;
//        img{
//            display: block;
//            width: 100%;
//            height: 100%;
//            object-fit: cover;
//        }
//    }
//}

@media screen and (max-width: 1440px) {
    #creator{
        background-size: contain;
    }
}
@media screen and (max-width: 768px) {
    //.swiper {
    //    .swiper-slide {
    //        width: 50% !important;
    //    }
    //}
}
@media screen and (max-width: 425px) {
    .content_creator{
        width: 100%;
    }
}
