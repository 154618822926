#sponsoring{
  position: relative;
  min-height: 800px;
  
  background: linear-gradient(#FB7A69, $primary);
  padding: 70px 0;

  display: flex;
  flex-wrap: wrap;
  .img_sponso{
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 90px;
    width: 90px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    figure{
      height: 90px;
      width: 90px;
      img{
        height: 100%;
        width: 100%;

        object-fit: contain;
        filter: grayscale(10);
      }
    }
  }
  .primary-block-left{
    position: absolute;
    bottom: 0;
    
    left: 0;

    width: 50px;
    height: 90px;

    background: $primary;
  }
  .primary-block-left-sponso{
    position: absolute;
    top: 155px;
    left: 195px;
    width: 250px;
    height: 90px;

    background: $primary;
    &::before{
      content: "";
      position: absolute;
      bottom: -130px;
      right: 30px;

      width: 90px;
      height: 190px;

      background: $dark;
    }
  }
  .primary-block-right-sponso{
    position: absolute;
    top: 50%;
    right: 195px;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 390px;

    background: $dark;
  }
}

#mes-projets{
  min-height: 500px;
  padding: 45px 0 70px 0;
  background: $light;
  color: $dark;
  position: relative;
  overflow: hidden;
  &>div{
      padding: 45px;
      display: flex;
      gap: 25px;
      background: rgba($color: $primary, $alpha: .05);
      border-radius: 25px;
      min-height: 500px;

      overflow: hidden;
      &>div{
          width: 31%;
          overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
          
          &:last-child{
              position: absolute;
              flex-grow: 1;
              right: -250px;
              top: 50%;
              transform: translate(0, -50%);
              width: 65%;
              padding: 10px 0; 
              .list-creator{
                  margin: 0 auto;
                  padding: 25px 0;
                  display: flex;
                  flex-wrap: nowrap;
                  justify-content: center;
                  gap: 25px;
                  transition: 500ms ease-in-out;
                  overflow-x: auto;
                  .item-creator{
                    width: 31%;
                    position: relative; 
                    display: flex;
                    flex-direction: column;
                    color: $dark;
                    .img-box-creator{
                        width: 100%;
                        height: 370px;
                        overflow: hidden;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        figure{
                            height: 370px;
                            img{
                                height: 100%;
                                width: 100%;
            
                                object-fit: cover;
                                border-radius: 5px;
                            }
                        }
                    }
                  }
              }
              .rightandleft{
                  background: $primary;
                  border-radius: 50%;
                  height: 35px;
                  width: 35px;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  margin-left: 15px;

                  font-size: 1.2rem;
                  font-weight: 700;
                  color: $light;
              }
          }
      } 
  }
}

// Media screen
@media screen and (max-width: 1024px) {
  #about{
    min-height: 450px;
    &>div{
      min-height: 450px;
      width: 70%;
      .quisommenous{
        margin-bottom: 15px;
      }
    }
  }
  
}
@media screen and (max-width: 768px) {
  #about{
    &>div{
      z-index: 10;
      h1{
        font-size: 2.2rem;
      }
    }
    .primary-block-left{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 30px;
      background: $primary;
    }
  
    .primary-block-right{
      width: 30px;
      background: $primary;
    }
  }

  #sponsoring{
    padding: 45px 0;
    .primary-block-left{
      bottom: 0;
      left: 0;
  
      width: 250px;
      height: 90px;
    }
    .primary-block-left-sponso{
      left: 0;
      height: 25px;
    }
    .primary-block-right-sponso{
      bottom: 0;
      right: 0;
      width: 75px;
      height: 190px;
    }
  }

  #preta{
    padding: 30px 0;
    gap: 10px;
    div{
      width: 100%;
    }
    .content_preta{
      flex-grow: 1;
      h2{
        font-size: 2.4rem;
        text-align: center;

        margin-bottom: 45px;
      }
      &>p{
        width: 100%;
        span{
          margin-top: 8px;
        }
      }
    }
    .img_preta{
      display: none;
      &::before{
        content: "";
        width: 400px;
        height: 40px;
        background: $primary;
  
        position: absolute;
        bottom: -40px;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  #about{
    align-items: stretch;
    min-height: 550px;
    padding-top: 120px;
    padding-bottom: 55px;
    &>div{
      h1{
        text-align: left;
      }
      p{
        text-align: left;
      }
    }
  }

  #sponsoring{
    max-height: 300px;
    .img_sponso{
      height: 250px;
      width: 250px;
      figure{
        height: 250px;
        width: 250px;
      }
    }
    .primary-block-left-sponso{
      display: none;
    }
    .primary-block-right-sponso{
      width: 35px;
      height: 50px;
    }
  }

  #preta{
    padding: 15px 0;
    gap: 10px;
    div{
      width: 90%;
      margin: 0 auto;
    }
    .content_preta{
      flex-grow: 0;
      h2{
        font-size: 2.2rem;
        text-align: center;

        margin-bottom: 15px;
        span{
          display: inline;
        }
      }
    }
  }
}